<template>
    <div class="prDiv">
        <el-row class="prRow" :gutter="40"  v-for="(item,i) in newList" :key="i">
          <el-col :span=6 class="anRowCen">
              <img :src="item.imgUrl" />
          </el-col>
          <el-col :span=18 class="prRowLeft">
              <div class="prDiv2">{{item.title}}</div>
              <div class="prDiv3">{{item.content}}</div>
              <div class="prMore" @click="moreClick(item)">MORE ></div>
          </el-col>
      </el-row>
      <el-row class="prRowFooter">
          <el-col :span=24>
              <el-pagination background  
              layout="prev, pager, next"  
              :total="count" 
              :page-size="5"
              @current-change=currentPageClick

              >
              </el-pagination>
          </el-col>
      </el-row>
    </div>
</template>
<script>
export default {
    name:"productResearch",
    data(){
        return{
            type:"all",
            temp:{imgUrl:require("../../static/product_img1.png"),
                title:"Wise大数据平台",
                content:`集数据建模与ETL设计于一体，在建模的同时同步完成ETL流程设计，提供一站式数据处理服务集数据建模与ETL设计于一体，
                在建模的同时同步完成ETL流程设计，提供一站式数据处理服务在建模的同时同步完成ETL流程设计，提供一站式数据处理服务`,
            },
            count:0,
            listData:[],
            newList:[]
        }
    },
    methods:{
        init(){
            let paramType= this.$route.params.type
            this.type=paramType
            this.getData(paramType)
        },
        getData(type){
            
            let selfList=[
                {
                   imgUrl:require("../../static/productSelf/nginx1.png"),
                    title:"Nginxwise",
                    content:`通过NGINXWISE 快速构建弹性伸缩的应用交付层，
                    利用VE/LTM/LVS实现NGINX+集群的高可用，N+实现业务流量的灵活控制（URL分流、灰度发布、限流等）支撑业务需求.
                    NGINXWISE实现NGINX+集群的统一纳管，提供监控、配置管理、流量分析等能力`,
                    id:"s1" 
                },
                {
                   imgUrl:require("../../static/productSelf/cowise1.png"),
                    title:"Cowise",
                    content:`1、流程定制
                    根据实际业务需求，通过模板及权限控制实现流程自动化
                    2、与其他平台无缝对接
                    输出标准化数据接口，可快速植入其他平台、系统
                    3、最大化保留用户习惯
                    平台可以便捷地进行线上编辑计算模板，配置计算公式、标准，生成计算结果等`,
                    id:"s2" 
                },
                {
                    imgUrl:require("../../static/productSelf/cloudwise1.png"),
                    title:"Cloudwise",
                    content:`CloudWise是一套可跨多云平台，提供数据迁移、备份、容灾的工具，可确保核心数据不丢失，业务不中断，故障秒恢复`,
                    id:"s3"             
                },
                {
                    imgUrl:require("../../static/productSelf/datawise1.png"),
                    title:"Datawise",
                    content:`大数据平台需支持多应用管理，即支持对应用的服务级别管理（SLA）。能够实现应用的访问资源控制，支持资源隔离。同时支持多租户功能`,
                    id:"s4"             
                },
                {
                    imgUrl:require("../../static/productSelf/iotwise1.png"),
                    title:"IoTWise",
                    content:`IotWise是基于边云协同的低代码PaaS开发平台，解决企业物联网应用开发难、迭代快的痛点，解决庞杂的流式数据处理、边云协同、人机协同的难点`,
                    id:"s5"             
                },
                {
                    imgUrl:require("../../static/productSelf/APIGateway.jpg"),
                    title:"APIGateway",
                    content:`作为 API 统一管控工具，快速实现互通互联和系统间集成对接；以 API 为核心，围绕 API 生命周期的各个阶段实现对 API 的统一管理，同时提供统一鉴权、统一流控、安全防护、统一监控等，保障服务安全稳定。`,
                    id:"s6"             
                }
            ]

            let agentList=[
                {
                    id:"a1",
                    title:"V-RAY",
                    content:`VRay是目前业界最受欢迎的渲染引擎。基于V-Ray 内核开发的有VRay for 3ds max、Maya、
                    Sketchup、Rhino等诸多版本，为不同领域的优秀3D建模软件提供了高质量的图片和动画渲染。`,
                    url:"https://www.chaosgroup.com/cn",
                    imgUrl:require("../../static/LOGO/chaos.png")
                },
                {
                    id:"a2",
                    title:"Loadrunner",
                    content:`LoadRunner，是一种预测系统行为和性能的负载测试工具。
                    通过以模拟上千万用户实施并发负载及实时性能监测的方式来确认和查找问题，
                    LoadRunner能够对整个企业架构进行测试。通过使用 LoadRunner，企业能最大限度地缩短测试时间，
                    优化性能和加速应用系统的发布周期。 LoadRunner是一种适用于各种体系架构的自动负载测试工具，
                    它能预测系统行为并评估系统性能。LoadRunner是通过代理方式截获客户端和服务器之间交互的数据流。`,
                    url:"https://www.microfocus.com/zh-cn/home",
                    imgUrl:require("../../static/LOGO/micro.png")
                },
                // {
                //     id:"a3",
                //     title:"Salesforce",
                //     content:`Tableau Desktop 是基于斯坦福大学突破性技术的软件应用程序。
                //     它帮助您生动地分析实际存在的任何结构化数据，以在几分钟内生成美观的图表、坐标图、仪表盘与报告。
                //     利用 Tableau 简便的拖放式界面，您可以自定义视图、布局、形状、颜色等等，帮助您展现自己的数据视角。
                //     Tableau Server 是软件应用程序，将 Tableau Desktop 中最新的交互式数据可视化内容、仪表盘、报告与工作簿的共享变得迅速简便。利用企业级的安全性与性能来支持大型部署。
                //     此外，提取选项帮助您管理您关键业务数据库上的负载。`,
                //     url:"https://www.tableau.com/",
                //    imgUrl:require("../../static/LOGO/tableau.png")
                // },
                {
                    id:"a4",
                    title:"来也科技",
                    content:`机器人流程自动化平台UiBot是国内RPA领航者，团队拥有十八年RPA项目经验与安全技术积累，
                    为企业和个人提供专业全面的机器人流程自动化解决方案。UiBot平台搭建的机器人，可通过用户使用界面，
                    智能理解企业已有应用，将基于规则的常规操作自动化，如自动重复读取邮件和系统，进行繁琐的计算，大批量生成文件和报告，
                    完成枯燥的文件检查等工作。RPA机器人能够大幅降低人力成本的投入，有效提高现有办公效率，准确、稳定、快捷地完成工作。
                    UiBot被广泛应用于财务税务、人力资源、金融业、制造业、信息技术、
                    运营销售等重复劳动率高的行业，机器人执行效率远高于人工操作，出错率极低，可7*24小时不间断运行。`,
                    url:"https://laiye.com/",
                   imgUrl:require("../../static/LOGO/laiye.png")
                },
                {
                    id:"a5",
                    title:"听云",
                    content:`端到端的全平台应用性能管理系统
                    听云平台，国内权威的应用性能监控平台之一，通过针对全平台的应用性能监控，
                    可以有效的帮助您改善应用的用户体验，
                    提升业务转化，减少用户流失。`,
                    url:"https://www.tingyun.com/",
                   imgUrl:require("../../static/LOGO/tingyun.png")
                },
                {
                    id:"a6",
                    title:"EPLAN",
                    content:`EPLAN作为电气计算机辅助设计时代的先锋，一直是为电气规划，
                    工程设计和项目管理领域提供智能化软件解决方案和专业化服务的全球标志型企业。
                    EPLAN 软件自1984年推出第一个版本，经过20余年的持续改进，它发展为享誉欧美电气设计领域的知名电气设计软件`,
                    url:"http://www.eplanp8.com/",
                    imgUrl:require("../../static/LOGO/EPLAN.png")
                },
                {
                    id:"a7",
                    title:"Materialise Magics",
                    content:`Materialise Magics随时都能为您的企业提供支持！ 这
                    针对增材制造而打造的通用数据准备和STL编辑软件具备STL文件格式转换、修复、设计编辑、
                    打印平台准备等多项功能。`,
                    url:"https://www.materialise.com/",
                    imgUrl:require("../../static/LOGO/materialise1.png")
                },
                {
                    id:"a8",
                    title:"ArtiosCAD雅图、Studio 3D工作室",
                    content:`ArtiosCAD雅图软件的一整套强大结构设计软件工具，能够帮助您在3D环境下完美实现您独特的包装结构设计。无论是您想要尝试一些独特的设计想法，或者是检查您的设计成果，在3D工作室的帮助下，
                    立刻就能让您设计的包装栩栩如生的呈现在您的面前，展示效果仿佛触手可及。 
Studio系列三维设计软件涵盖了针对整个包装市场的解决方案：标签，卡纸，软包，收缩膜，虚拟超市，
包装印后特效软件，包装盒设计软件。Stuido集成在Adobe Illustrator中，使用方便易懂，
因为它能够直接使用您的数据进行设计工作，将Adobe Illustrator瞬间变成一个三维设计软件。
它可以创建令人振奋的三维包装模型，从带有三维模型的PDF文件到动画，或模拟的包装过程，
您都可以与客户分享。`,
                    url:"http://www.esko.com.cn/",
                    imgUrl:require("../../static/LOGO/ESKO.jpg")
                },
                {
                    id:"a9",
                    title:"Untiy3D",
                    content:`一个让玩家轻松创建诸如三维视频游戏、建筑可视化、实时三维动画等类型互动内容的多平台的综合型游戏开发工具，
                    是一个全面整合的专业游戏引擎。`,
                    url:"https://unity.cn/",
                   imgUrl:require("../../static/LOGO/unity.png")
                },
                {
                    id:"a10",
                    title:"VantiQ",
                    content:`一套基于事件驱动引擎的PaaS实时敏捷开发工具，可快速对接物联网各类协议，快速抓取流式数据，对接打通不同的异构系统，通过事件驱动的引擎，根据客户业务逻辑需求，
                    快速开发出各类应用。同时支持分布式和边缘计算。`,
                    url:"http://vantiq.com.cn/",
                    imgUrl:require("../../static/LOGO/VANTIQ.png")
                },
                {
                    id:"a11",
                    title:"汉仪字体",
                    content:`正版字体`,
                    url:"http://www.hanyi.com.cn/",
                     imgUrl:require("../../static/LOGO/hanyi.png")
                },
                {
                    id:"a12",
                    title:"windows、office等微软 产品",
                    content:`操作系统`,
                    url:"https://www.microsoft.com/",
                    imgUrl:require("../../static/LOGO/weiruan.png")
                },
                {
                    id:"a13",
                    title:"CAD、MAX、Maya等系列软件",
                    content:`欧特克有限公司（“欧特克”或“Autodesk”）是全球最大的二维和三维设计、工程与娱乐软件公司，为制造业、工程建设行业、
                    基础设施业以及传媒娱乐业提供卓越的数字化设计、工程与娱乐软件服务和解决方案`,
                    url:"https://www.autodesk.com.cn/",
                    imgUrl:require("../../static/LOGO/Autodesk.png")
                },
                {
                    id:"a14",
                    title:"PS,Substance等系列软件",
                    content:`Adobe:创意、营销和文档管理解决方案`,
                    url:"https://www.adobe.com/",
                    imgUrl:require("../../static/LOGO/Adobe.png")
                },
                {
                    id:"a15",
                    title:"minitab",
                    content:`Minitab软件是为质量改善、教育和研究应用领域提供统计软件和服务的先导。是全球领先的质量管理和六西格玛实施软件工具，
                    更是持续质量改进的良好工具软件。`,
                    url:"http://minitab.couponcad.com/",
                    imgUrl:require("../../static/LOGO/minitab.png")
                },
                {
                    id:"a16",
                    title:"Zbrush",
                    content:`是一款专业数字雕刻、绘画软件、3D设计软件`,
                    url:"https://support.pixologic.com/",
                    imgUrl:require("../../static/LOGO/pixologic.png")
                },
                {
                    id:"a17",
                    title:"Aveva-PDMS",
                    content:`英国计算机软件商。为造船和海洋工程、石油和天然气、造纸、电力、
                    化工和制药等工业领域提供全生命周期解决方案及服务`,
                    url:"https://www.aveva.com/",
                    imgUrl:require("../../static/LOGO/AVEVA.png")
                },
                {
                    id:"a18",
                    title:"Oracle",
                    content:`甲骨文数据库服务器`,
                    url:"https://www.oracle.com/index.html",
                    imgUrl:require("../../static/LOGO/Oracle1.png")
                },
                {
                    id:"a19",
                    title:"Coreldrew",
                    content:`加拿大渥太华，绘画与插图、文本操作、绘图编辑、桌面出版及版面设计`,
                    url:"https://www.coreldraw.com/cn/",
                    imgUrl:require("../../static/LOGO/corel.png")
                },
                {
                    id:"a20",
                    title:"金山WPS",
                    content:`WPS Office是由金山软件股份有限公司自主研发的一款办公软件套装，
                    可以实现办公软件最常用的文字、表格、演示，PDF阅读等多种功能`,
                    url:"https://www.wps.cn/",
                    imgUrl:require("../../static/LOGO/jinshan.png")
                },
                {
                    id:"a21",
                    title:"Dynatrace",
                    content:`是一款让企业云计算化繁为简、加快企业数字化转型的平台。依托人工智能技术和完善的自动化功能，
                    Dynatrace 一体化平台能提供有关应用性能、底层基础设施以及所有用户体验的数据。`,
                    url:"https://www.dynatrace.cn/",
                    imgUrl:require("../../static/LOGO/dynatrace.png")
                },
                {
                    id:"a22",
                    title:"PTC-core",
                    content:`PTC Creo 是一套可伸缩、可互操作的产品设计软件，能快速实现价值。
                    它帮助团队创建、分析、查看产品设计方案，并在下游利用这些信息`,
                    url:"https://www.ptc.com/cn/about",
                    imgUrl:require("../../static/LOGO/PTC.png")
                },
                {
                    id:"a23",
                    title:"Ansys",
                    content:`ANSYS软件是美国ANSYS公司研制的大型通用有限元分析（FEA）软件，是世界范围内增长最快的计算机辅助工程（CAE）软件，能与多数计算机辅助设计（CAD，computer Aided design）软件接口，实现数据的共享和交换，如Creo, NASTRAN、Algor、I－DEAS、AutoCAD等。
                    是融结构、流体、电场、磁场、声场分析于一体的大型通用有限元分析软件。`,
                    url:"https://www.ansys.com/zh-cn",
                    imgUrl:require("../../static/LOGO/ANSYS.png")
                },
                {
                    id:"a24",
                    title:"SolidWorks",
                    content:`达索系统（Dassault Systemes ）下的子公司，专门负责研发与销售机械设计软件的视窗产品，
                    公司总部位于美国马萨诸塞州。`,
                    url:"https://www.solidworks.com/zh-hans",
                    imgUrl:require("../../static/LOGO/SolidWorks.png")
                },
                // {
                //     id:"a25",
                //     title:"浩辰CAD",
                //     content:`国产CAD涉及软件，包含二维三维产品`,
                //     url:"https://www.gstarcad.com/",
                //     imgUrl:require("../../static/LOGO/haochen.png")
                // },
                {
                    id:"a26",
                    title:"enscape",
                    content:`Enscape 是专门为建筑、规划、景观及室内设计师打造的渲染产品，
                    它支持 Revit、SketchUp、Rhino、ArchiCAD 和 Vectorworks 五个设计师最常用的设计软件平台。
                    你无需导入导出文件，在常用的软件内部即可看到逼真的渲染效果；你无需了解记忆各种参数的用法，
                    一切都是傻瓜式的一键渲染，你可以把精力更多地投入到设计中去；你无需坐在那里一次次等待渲染结果，一切都是实时的，
                    普通场景只要在几秒内就会见到照片级的渲染结果。`,
                    url:"https://enscape3d.com/",
                    imgUrl:require("../../static/LOGO/Enscape.png")
                },
                {
                    id:"a27",
                    title:"undet",
                    content:`Undet是由Undet Software发布的点云处理插件Undet for SketchUp。使用该插件，AEC行业的从业人员可以借助SketchUp平台更加方便快速地参考扫描的真实点云数据建立BIM模型，
                    支持 SketchUp、Revit 和 Autocad 软件。`,
                    url:"https://www.undet.com/",
                    imgUrl:require("../../static/LOGO/UNDET.jpg")
                },
                {
                    id:"a28",
                    title:"华途DEX/ODC",
                    content:`文档加密管理软件，U盘加密以及DLP产品`,
                    url:"http://www.huatusoft.com/index.html",
                    imgUrl:require("../../static/LOGO/huatu.png")
                }
            ]

            if(type=="all"){
                this.count=33
                this.listData=selfList.concat(agentList)
            }else if(type=="self"){
                this.count=6
                 this.listData=selfList
            }else if(type=="agent"){
                this.count=27
                this.listData=agentList
            }
           
            this.currentPageClick(1)
        },
         currentPageClick(page){
            this.newList=[]
            // this.currentPage=page
            let start=(page-1)*5
            let list=[]
            for(let i=0;i<5;i++){
                if(start+i+1<=this.listData.length){
                    let item=this.listData[start+i]
                    list.push(item)
                }
                
            }
            this.newList=list
            this.$forceUpdate()
        },
         moreClick(row){
             if(row.url!=undefined){
                 window.open(row.url,"_blank")
             }else{
                 this.$router.push({
                    name:"productInfo",
                    query:{
                        id:row.id,
                        name:row.title,
                        page:this.type

                    }
                })
             }
           
       }

    },
    created(){
        this.init()
    },
     watch: {
        $route() {
            this.init()  //监听路由变化
        }
    },
}
</script>
<style>
.prDiv{
    width:100%;
    padding:60px 0;
}
.prRow{
    padding:20px 0;
    border:1px solid #DBE3EF;
    position:relative;
}
.prRow .anRowCen{
    text-align:center
}
.prRow .prDiv1{
    font-size:14px;
    line-height: 24px;
}
.prRow .prRowRight{
    text-align:center
}

.prRow .prMore
{
    font-size:14px;
    color:#3C6AFB;
    margin-top: 20px;
    cursor:pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    font-weight: bold;
}
.prRowLeft .prDiv2{
    height: 32px;
    font-size: 18px;
    color: #404756;
    line-height: 32px;
}
.prRowLeft .prDiv3{
    /* height: 26px; */
    font-size: 14px;
    color: #848484;
    line-height: 26px;
    margin-top:20px

}
.prRowFooter{
    text-align: right;
    padding-top:60px;
}
</style>
